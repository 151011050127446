const featuredImage = `
  featuredImage {
    node {
      altText
      sourceUrl(size: $imageSize)
      caption
    }
  }
`;

export default featuredImage;
