import PropTypes from 'prop-types';
import cn from 'classnames';
import { useRef } from 'react';
import SearchIcon from '../Header/svg/Search';
import Close from '../Header/svg/Close';
import styles from './SearchForm.module.scss';

export default function SearchForm({ isOpen, handleClose }) {
  const form = useRef(null);

  const handleKeypress = (e) => {
    if (e.keyCode === 13) form.current.submit();
  };

  return (
    <div className={cn(styles.form__wrapper, { [styles.open]: isOpen })}>
      <form className={styles.form} method="get" action="/search" ref={form}>
        <SearchIcon className={styles.search} />
        <input className={styles.search__input} name="q" type="text" placeholder="Search" onKeyPress={handleKeypress} />
        <Close className={styles.close} onClick={handleClose} />
      </form>
    </div>
  );
}

SearchForm.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

SearchForm.defaultProps = {
  isOpen: false,
  handleClose: () => false,
};
