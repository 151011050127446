import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { postTypes } from '@/lib/wp/postTypes';
import styles from '../BlockArticleCard.module.scss';

/**
 * Render a List variation of a card
 *
 * @param  {object}  props          component attributes
 * @param  {object}  props.data     graphql post data
 * @param  {string}  props.postType card post type
 * @return {Element}                List card
 */
export default function List({ data, postType }) {
  const tags = data?.[postType]?.terms?.nodes;
  let firsttag = data?.[postType]?.primaryTag?.primaryTag;
  const posttype = postType;

  return (
    <>
      <div className={styles.list_image_wrapper}>
        <div className={styles.article__card_image}>
          {data?.[postType]?.featuredImage ? (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src={data?.[postType]?.featuredImage?.node?.sourceUrl}
              alt={data?.[postType]?.featuredImage?.node?.altText}
            />
          ) : (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src="/images/Placeholder.jpg"
              alt="Placeholder"
            />
          )}
        </div>
      </div>
      <div className={styles.inline_content}>
        <ul className={styles.article__card_tags}>
          {firsttag ? <li key={firsttag?.id}>
                  <a href={`/${firsttag?.taxonomy?.node?.graphqlSingleName}/${firsttag?.slug}`}>{firsttag?.name}</a>
                </li> :
                <>
                {data?.[postType]?.terms?.nodes.length &&
                  data?.[postType]?.terms?.nodes
                    ?.filter((x) => !!x.id)
                    ?.slice(0, 1)
                    ?.map((term) => (
                      <li key={term?.id}>
                        <a href={`/${term?.taxonomy?.node?.graphqlSingleName}/${term?.slug}`}>{term?.name}</a>
                      </li>
                    ))}
                </>
                }
        </ul>
        <h3 className={`${styles.article__card_title} ${styles.article__card_title_list}`}>
          <a href={`/${postTypes[postType]?.route}/${data?.[postType]?.slug}?id=${data?.[postType]?.databaseId}`}>{data?.[postType]?.title}</a>
        </h3>
        <Moment format="MMM DD YY" className={styles.article__card_date}>
          {data?.[postType]?.date}
        </Moment>
        <span className={styles.article__card_reading}>{data?.[postType]?.readingTime} read</span>
      </div>
    </>
  );
}

List.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      date: PropTypes.string,
      focalPointX: PropTypes.number,
      focalPointY: PropTypes.number,
      terms: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            graphqlPluralName: PropTypes.string,
          })
        ),
      }),
      slug: PropTypes.string,
      title: PropTypes.string,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
          altText: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  postType: PropTypes.string.isRequired,
};
