/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import Error from 'next/error';
import { useWPApollo } from '@/lib/wp/connector';
import MenuContext from '@/lib/context/menu-context';
import { DefaultSeo } from 'next-seo';
import ErrorPage from '@/components/ErrorPage/ErrorPage'
import '../styles/index.scss';
import LogRocket from 'logrocket';
/**
 * Render Home Page
 *
 * @param  {object}  props           component attributes
 * @param  {object}  props.Component component to display
 * @param  {object}  props.pageProps page components as props
 * @return {Element}                 App component
 */
export default function App({ Component, pageProps }) {
  LogRocket.init(process.env.LOG_ROCKET_PROJECT);

  const apolloClient = useWPApollo(pageProps);

  const error = pageProps?.error;


  let errorMessage = pageProps?.errorMessage ?? 'An unknown error occurred.';
  errorMessage = errorMessage.replace(/\.$/g, '');

  const {
    defaultSeo: { social, ...defaultSeoData } = {},
    menus,
    algolia,
    preview,
    session,
    ...passThruProps
  } = pageProps;

  const componentProps = {
    ...passThruProps,
    post: {
      ...passThruProps?.post,
      seo: {
        ...passThruProps?.post?.seo,
        social,
      },
    },
  };

  return (
    <div>
      <ApolloProvider client={apolloClient}>
        <MenuContext.Provider value={pageProps?.menus}>
          {error ? (
            //<Error statusCode={500} title={errorMessage} />
            <ErrorPage title="We're sorry, that page does not exist." />
          ) : (
            <>
              {defaultSeoData && <DefaultSeo {...defaultSeoData} />}
              <Component {...componentProps} />
            </>
          )}
        </MenuContext.Provider>
      </ApolloProvider>
    </div>
  );
}

export function reportWebVitals({ id, name, label, value }) {
  if (typeof window !== undefined) {
      window?.ga?.("send", "event", {
          eventCategory:
              label === "web-vital" ? "Web Vitals" : "HDM Site Metric",
          eventAction: name,
          eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
          eventLabel: id, // id unique to current page load
          nonInteraction: true, // avoids affecting bounce rate.
      });
  }
}


App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
};
