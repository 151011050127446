import globalPostFields from '@/lib/wp/_partials/globalPostFields';
import featuredImageFields from '@/lib/wp/media/featuredImage';
import primaryTag from '@/lib/wp/_partials/primaryTag';

const postCardField = `
  ${globalPostFields}
  ${featuredImageFields}
  ${primaryTag} 
  excerpt
  focalPointX
  focalPointY
  readingTime
  terms {
    nodes {
      ... on Tag {
        id
        name
        slug
        taxonomy {
          node {
            graphqlSingleName
          }
        }
      }
      ... on Category {
        id
        name
        slug
        taxonomy {
          node {
            graphqlSingleName
          }
        }
      }
    }
  }
`;

export default postCardField;
