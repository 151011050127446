import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Container.module.scss';

/**
 * Renders a container for grid layouts
 *
 * @param  {object}  props           component props
 * @param  {object}  props.children  children nodes
 * @param  {string}  props.className additional classname
 * @param  {boolean} props.condensed Should the container be condensed (TRUE) or not (FALSE)
 * @return {Element}                 Container component
 */
export default function Container({ children, className, condensed, nomargin }) {
  return (
    <div className={cn(styles.container, className, {[styles.nomargin]: nomargin})}>
      {condensed ? <div className={styles.condensed}>{children && children}</div> : children && children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  condensed: PropTypes.bool,
};

Container.defaultProps = {
  className: '',
  condensed: false,
};
