import cn from 'classnames';
import Router from 'next/router';
import MenuContext from '@/lib/context/menu-context';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import SubNav from '../SubNav';
import styles from './NavItem.module.scss';
/**
 * Render the NavItem component
 *
 * @param  {object}  props              component attributes as props
 * @param  {object}  props.isTopLevel   Checks for menu hierarchy for toggler and styles
 * @param  {object}  props.title        Title for NavList Item
 * @param  {object}  props.url          URL for NavList Item
 * @param  {object}  props.menuChildren Sub Menu items from WP menus
 * @param  {bool}    props.isDark       Whether menu uses dark variation
 * @return {Element}                    NavItem component
 */
function NavItem({ isTopLevel = false, url, title, menuChildren = [], setLoading, isLoading, isDark }) {
  const [isTopicExpanded, setIsTopicExpanded] = useState(false);

  const menus = useContext(MenuContext)?.primary_menu;

  const navId = `nav_${title}`;

  const onSubNavOutHandler = (e) => {
    e.stopPropagation();
    if (e.clientY > 90) {
      setIsTopicExpanded(false);
    }
  };

  const onGlobalNavOutHandler = (e) => {
    e.stopPropagation();
    if (e.clientY < 86) {
      setIsTopicExpanded(false);
    }
  };

  const onGlobalNavClickHandler = (e) => {
    e.stopPropagation();
    setIsTopicExpanded(!isTopicExpanded);
  };

  /*
  #################### Holding onto this useEffect for now ####################
  useEffect(() => {
    const onScroll = () => {
      setIsTopicExpanded(false);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [isTopicExpanded]);
  #############################################################################
  */

  return isTopLevel ? (
    <li className={isDark ? styles.dark : {}}>
      <a
        className={cn(styles.navItem__link, isTopicExpanded ? styles.navItem__link__opened : {})}
        onClick={(e) => onGlobalNavClickHandler(e)}
        onMouseLeave={(e) => onGlobalNavOutHandler(e)}
        role="button"
        tabIndex="0"
        id={navId}
        onKeyPress={(e) => onGlobalNavClickHandler(e)}
      >
        <span>{title}</span>
        <span className={cn(styles.navItem__caret, isTopicExpanded ? styles.navItem__caret__opened : {})} />
      </a>

      <SubNav
        isTopicExpanded={isTopicExpanded}
        menuChildren={menuChildren}
        isLoading={isLoading}
        setLoading={setLoading}
        handleMouseLeave={(e) => onSubNavOutHandler(e)}
      />
    </li>
  ) : (
    <li
      className={cn(styles.navItem, styles.navItem__topLevel, isDark ? styles.dark : {})}
    >
      <div
        onClick={() => {
          menus.forceClose();
          setLoading(true);
          Router.push(url);
        }}
        className={styles.navlink}
      >
        {title}
      </div>
    </li>
  );
}

NavItem.propTypes = {
  isTopLevel: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  menuChildren: PropTypes.array,
  setLoading: PropTypes.func,
  isDark: PropTypes.bool
};

NavItem.defaultProps = {
  isTopLevel: false,
  title: '',
  url: '',
  menuChildren: [],
  setLoading: () => {},
  isDark: false,
};

export default NavItem;
