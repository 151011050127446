import { gql } from '@apollo/client';
import defaultPageData from '@/lib/wp/_partials/defaultPageData';
import seoPostFields from '@/lib/wp/_partials/seoPostFields';
import globalPostFields from '@/lib/wp/_partials/globalPostFields';

const queryPartnersArchive = gql`
  query GET_ARCHIVE_PARTNERS(
    $first: Int
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
  ) {
    ${defaultPageData}
    homepageSettings {
      postsPage {
        ${seoPostFields}
      }
    }
    partners(
      first: $first
      where: { orderby: { field: $orderBy, order: $order }}
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          partnerInformation {
            channelName
            partnerLogo {
              ... on MediaItem {
                sourceUrl
              }
            }
            partnerLink {
              url
              title
            }
            partnerAccentColor
            featuredImageBackgroundColor
            partnerFollowCode
            partnerFeatured {
              ... on Post {
                databaseId
              }
            }
            partnerAbout
          }
          blocksJSON
          ${globalPostFields}
        }
      }
    }
  }
`;

export default queryPartnersArchive;
