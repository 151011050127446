import { useState } from 'react';
import cn from 'classnames';
import styles from './Header.module.scss';
import Nav from './Nav';
import Logo from '../Logo';
import Hamburger from './svg/Hamburger';
import Search from './svg/Search';
import Close from './svg/Close';
import SearchForm from '../SearchForm';
import PropTypes from "prop-types";

/**
 * Render Header
 * @param  {object}  props                    component attributes
 * @param  {object}  props.isDark             does the header use the dark variation
 * @return {Element}                          Header component
 */
export default function Header({isDark}) {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  return (
    <div className={cn(styles.headerContainer, isDark ? styles.dark : {})}>
      <header className={styles.header}>
        <button
          className={styles.header__hamburger}
          onKeyPress={() => setMenuOpened(!isMenuOpened)}
          onClick={() => setMenuOpened(!isMenuOpened)}
          type="button"
        >
          {isMenuOpened ? <Close /> : <Hamburger />}
        </button>
        <Logo isDark={isDark} />
        <div className={cn(styles.header__menuContainer, isMenuOpened ? styles.header__menuContainer__opened : {})}>
          <Nav isMenuOpened={isMenuOpened} isDark={isDark} closeMenu={() => {setMenuOpened(false)}} />
          <div className={styles.header__menuContainer__bottom}>
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className={cn(styles.header__login__btn, isMenuOpened ? styles.header__login__btn__opened : {})}*/}
            {/*>*/}
            {/*  Login*/}
            {/*</button>*/}
          </div>
        </div>
        <div className={styles.header__search}>
          <Search onClick={() => setIsSearchOpen(true)} />
        </div>
      </header>
      <SearchForm isOpen={isSearchOpen} handleClose={() => setIsSearchOpen(false)} />
    </div>
  );
}

Header.propTypes = {
  isDark: PropTypes.bool,
}

Header.defaultProps = {
  isDark: false,
};
