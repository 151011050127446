/**
 * Render the Hamburger component
 *
 * @return {Element} Hamburger component
 */
function Hamburger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22">
      <defs />
      <g transform="translate(-11.663 -11.07)">
        <rect className="a" width="28" height="4" transform="translate(11.663 11.07)" />
        <rect className="a" width="28" height="4" transform="translate(11.663 20.07)" />
        <rect className="a" width="28" height="4" transform="translate(11.663 29.07)" />
      </g>
    </svg>
  );
}

export default Hamburger;
