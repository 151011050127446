import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './SubNav.module.scss';
import NavItem from '../NavItem';
/**
 * Render the Post component
 *
 * @param  {object}   props                  component attributes as props
 * @param  {object}   props.isTopicExpanded  post data from WP
 * @param  {object}   props.menuChildren     sub links from the WP Menu
 * @param  {Function} props.handleMouseLeave func
 * @return {Element}                         Post component
 */
function SubNav({ isTopicExpanded = false, menuChildren = [], handleMouseLeave,setLoading, isLoading }) {
  return (
    <ul
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseLeave}
      className={cn(styles.subNav, isTopicExpanded ? styles.subNav__opened : {}, 'ibm-plex-sans')}
    >
      {menuChildren?.map((child) => <NavItem isLoading={isLoading} key={child.id} url={child.path} title={child.label} setLoading={setLoading} />)}
    </ul>
  );
}

SubNav.propTypes = {
  isTopicExpanded: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  menuChildren: PropTypes.array,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  handleMouseLeave: PropTypes.func
};

SubNav.defaultProps = {
  isTopicExpanded: false,
  menuChildren: [],
  isLoading:false,
  setLoading:()=>{},
   handleMouseLeave: () => false,
};

export default SubNav;
