import { gql } from '@apollo/client';

const queryConversationArchive = gql`
  query GET_ARCHIVE_CONVERSATIONS(
    $first: Int
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
  ) {
    conversations(first: $first, where: { orderby: { field: $orderBy, order: $order } }) {
      edges {
        node {
          databaseId
          date
          conversations {
            author {
              slug
              name
              nickname
              social {
                twitter
                linkedin
              }
              avatar {
                url
              }
            }
            content
            byline
            postUrl
            profilepicture {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default queryConversationArchive;
