const authorFields = `
  author {
    node {
      name
      slug
      nickname
      social {
        twitter
        linkedin
      }
      avatar {
        url
      }
    }
  }
`
export default authorFields