/**
 * @param  {string}   props0.className
 * @param  {Function} props0.onClick
 * @return {Element}                   Close component
 */
export default function Close({ className, onClick }) {
  const style = {
    fill: 'none',
    stroke: '#000',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '4px',
  };

  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <g id="cross">
        <line className="cls-1" x1="7" x2="25" y1="7" y2="25" style={style} />
        <line className="cls-1" x1="7" x2="25" y1="25" y2="7" style={style} />
      </g>
    </svg>
  );
}
