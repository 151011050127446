import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MenuContext from '@/lib/context/menu-context';
import cn from 'classnames';
import styles from './Nav.module.scss';
import NavItem from '../NavItem';
/**
 * Render the Nav component
 *
 * @param  {object}   props               component attributes as props
 * @param  {boolean}  props.isMenuOpened  whether menu is open
 * @param  {boolean}  props.isDark        whether menu uses dark variation
 * @return {Element}                      Post component
 */
function Nav({ isMenuOpened, isDark, closeMenu }) {
  const menus = useContext(MenuContext)?.primary_menu;
  const [loading, setLoading] = useState(false);

  if (menus) {
    menus.forceClose = () => {
      setTimeout(() => {
        closeMenu();
      }, 4350);
    }
  }

  setTimeout(() => {
    loading && setLoading(false);
  }, 4000);

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle className={styles.path} cx="25" cy="25" r="20" fill="none" stroke-width="5"/>
          </svg>
        </div>
      )}
      <ul className={cn(styles.nav, isMenuOpened ? styles.nav__opened : {}, isDark ? styles.dark : {})}>
        {menus?.map((item) => (
          <NavItem
            key={item?.id}
            url={item?.path}
            title={item?.label}
            isTopLevel={item?.children?.length}
            menuChildren={item?.children}
            setLoading={setLoading}
            isLoading={loading}
            isDark={isDark}
          />
        ))}
      </ul>
    </>
  );
}
export default Nav;

Nav.propTypes = {
  isMenuOpened: PropTypes.bool,
  isDark: PropTypes.bool,
  closeMenu: PropTypes.func
};
Nav.defaultProps = {
  isMenuOpened: false,
  isDark: false,
};
