const eventFields = `
  events {
    status
    attendanceMode
    url
    description
    location
    cost
    startDate
    startTime
    endDate
    endTime
    organizerName
    organizerUrl
    sponsors
  }
`;

export default eventFields;
