import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';
import videoFields from '@/lib/wp/_partials/videoFields';
import authorFields from '../_partials/authorFields';


const getVideoById = gql`
  query GET_VIDEO_BY_ID($id: ID!, $idType: VideoIdType = DATABASE_ID, $imageSize: MediaItemSizeEnum = LARGE) {
    video(id: $id, idType: $idType) {
      ${postCardFields}
      ${authorFields}
      ${videoFields}
    }
  }
`;

export default getVideoById;
