import { gql } from '@apollo/client'
import featuredImageFields from '../media/featuredImage'



const queryAudioById = gql`
query GET_AUDIO_BY_ID(
    $id: ID!
    $idType: AudioIdType = DATABASE_ID
    $imageSize: MediaItemSizeEnum = LARGE
  ){
    audio(id: $id, idType: $idType) {
            id
            databaseId
            title
            slug
            blocksJSON
            ${featuredImageFields}
          }
  }
`
export default queryAudioById