const globalPostFields = `
  databaseId
  date
  slug
  uri
  title
  status
  excerpt
  readingTime
  focalPointX
  focalPointY
  uri
  contentType {
    node {
      graphqlSingleName
    }
  }
`;

// focalPointX
// focalPointY
// readingTime
// terms {
//   nodes {
//     ... on Tag {
//       id
//       name
//       slug
//       taxonomy {
//         node {
//           graphqlSingleName
//         }
//       }
//     }
//     ... on Category {
//       id
//       name
//       slug
//       taxonomy {
//         node {
//           graphqlSingleName
//         }
//       }
//     }
//   }
// }    


export default globalPostFields;
