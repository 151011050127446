import { gql } from '@apollo/client';
import globalPostFields from '@/lib/wp/_partials/globalPostFields';
import eventFields from '@/lib/wp/_partials/eventFields';
import bylineFields from '@/lib/wp/_partials/byline';
import featuredImage from '@/lib/wp/media/featuredImage';
import defaultPageData from '@/lib/wp/_partials/defaultPageData';


const queryEventById = gql`
  query GET_EVENT_BY_ID($id: ID!, $idType: EventIdType = DATABASE_ID, $imageSize: MediaItemSizeEnum = LARGE) {
    ${defaultPageData}
    event(id: $id, idType: $idType) {
      ${globalPostFields}
      ${featuredImage}
      ${bylineFields}
      ${eventFields}
      blocksJSON
    }
  }
`;

export default queryEventById;
