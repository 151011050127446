import PropTypes from 'prop-types';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import queryPosts from '@/lib/wp/content/getPost';
import queryVideo from '@/lib/wp/content/getVideoById';
import queryAudioById from '@/lib/wp/audio/getAudioById';
import queryConversations from '@/lib/wp/conversations/queryConversations';
import queryPartners from '@/lib/wp/partners/queryPartners';
import styles from './BlockArticleCard.module.scss';
import SmallRow from './variations/SmallRow';
import Inline from './variations/Inline';
import FeatureSmall from './variations/FeatureSmall';
import FeatureMedium from './variations/FeatureMedium';
import FeatureFull from './variations/FeatureFull';
import Condensed from './variations/Condensed';
import List from './variations/List';
import InFocus from './variations/InFocus';
import queryEventById from '@/lib/wp/events/queryEventById';

/**
 * Renders an article card to be used in other blocks
 *
 * @param  {object}  props                     component props
 * @param  {number}  props.postID              database id of post
 * @param  {string}  props.postType            wp posttype of post
 * @param  {string}  props.cardType            card variation to display
 * @param  {number}  props.columnSpan          how many columns to span card
 * @param  {bool}    props.whiteBackground     does card have a white background
 * @param  {bool}    props.lightText           does card have light text
 * @param  {string}  props.accentColorOverride optional color override for drop shadow
 * @param  {object}  props.postData            already loaded post data to avoid a gql call
 * @return {Element}                           article card element
 */
export default function BlockArticleCard({
  postID,
  postType,
  cardType,
  columnSpan,
  whiteBackground,
  lightText,
  accentColorOverride,
  postData,
  initialPostData
}) {

  let data; 
  let loading; 
  let error;

  const queryMap = {
    post: queryPosts,
    hdm_videos: queryVideo,
    video: queryVideo,
    conversation: queryConversations,
    partner: queryPartners,
    audio: queryAudioById,
    event: queryEventById,
  };
  const query = queryMap[postType];
  if (postType == 'hdm_videos') {
    postType = 'video';
  }

  if (!query) {
    return <></>;
  }

  
  // console.log(query)
  if(initialPostData && Object.keys(initialPostData).length) {
    loading = false;
    error = false;
    data = initialPostData;
  } else {
    let queryData = useQuery(query, { variables: { id: postID }, skip: Object.keys(postData).length });
    loading = queryData.loading;
    error = queryData.error;
    data = queryData.data;
  }

  const cardTypeMap = {
    'small-row': SmallRow,
    inline: Inline,
    'feature-small': FeatureSmall,
    'feature-medium': FeatureMedium,
    'feature-full': FeatureFull,
    condensed: Condensed,
    list: List,
    'in-focus': InFocus,
  };

  const Card = cardTypeMap[cardType || "inline"];

  if (loading) {
    return (
      <div>
        Loading data for {postType} #{postID}...
      </div>
    );
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  const backgroundClass = whiteBackground ? styles.white : '';
  const lightTextClass = lightText ? styles.light : '';

  const colorOverrideStyle =
    accentColorOverride !== '' &&
    (cardType === 'feature-small' || cardType === 'feature-medium' || cardType === 'feature-full')
      ? { 'boxShadow': '9px 9px 0 ' + accentColorOverride }
      : {};

  if (data || Object.keys(initialPostData || postData).length) {
    return (
      <div
        style={colorOverrideStyle}
        className={cn(
          styles.article__card,
          styles?.[`article__card_${cardType}`],
          styles?.[`columns-${columnSpan}`],
          styles?.[`${cardType}-${columnSpan}`],
          backgroundClass,
          lightTextClass,
          `columns-${columnSpan}`,
          `article__card`
        )}
      >
        {Object.keys(initialPostData || postData).length ? (
          <>
            <Card data={{ [postType]: initialPostData || postData }} postType={postType} />
          </>
        ) : (
          <Card data={data} postType={postType} />
        )}
      </div>
    );
  }

  return <div>Sorry something went wrong</div>;
}

BlockArticleCard.propTypes = {
  postID: PropTypes.number.isRequired,
  postType: PropTypes.string.isRequired,
  cardType: PropTypes.string,
  columnSpan: PropTypes.number,
  whiteBackground: PropTypes.bool,
  lightText: PropTypes.bool,
  accentColorOverride: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  postData: PropTypes.any,
  initialPostData: PropTypes.any
};

BlockArticleCard.defaultProps = {
  cardType: 'small-row',
  columnSpan: 0,
  whiteBackground: false,
  lightText: false,
  accentColorOverride: '',
  postData: {},
  initialPostData: null
};
