const videoFields = `
  videoinformation {
    description
    transcription
    contentType
    duration
    videoFile {
      mediaItemUrl
      mimeType
    }
  }
`;

export default videoFields;
