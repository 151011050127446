import styles from './Logo.module.scss';
import PropTypes from "prop-types";
import cn from "classnames";
/**
 * Render Logo
 * @param  {object}  props          component attributes
 * @param  {object}  props.isDark   does the header use the dark variation
 * @return {Element}                Logo component
 */
export default function Logo({isDark}) {
  return (
    <div className={cn(styles.logo, isDark ? styles.dark : {})}>
      <a href="/">
        <p>HealthData</p>
        <p>Management</p>
      </a>
    </div>
  );
}

Logo.propTypes = {
  isDark: PropTypes.bool,
}

Logo.defaultProps = {
  isDark: false,
};

