import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { postTypes } from '@/lib/wp/postTypes';
import styles from '../BlockArticleCard.module.scss';

/**
 * Render a Inline variation of a card
 *
 * @param  {object}  props          component attributes
 * @param  {object}  props.data     graphql post data
 * @param  {string}  props.postType card post type
 * @return {Element}                Inline card
 */
export default function Inline({ data, postType }) {
  const tags = data?.[postType]?.terms?.nodes;
  let firsttag = data?.[postType]?.primaryTag?.primaryTag || tags?.find((tag) => tag.__typename === 'Tag');
  return (
    <>
      <div className={styles.inline_content}>
        <ul className={styles.article__card_tags}>
          {data?.[postType]?.terms?.nodes.length && (
            <li>
              <a href={`/${firsttag?.taxonomy?.node?.graphqlSingleName}/${firsttag?.slug}`}>{firsttag?.name}</a>
            </li>
          )}
        </ul>
        <h3 className={styles.article__card_title}>
          <a href={`/${postTypes[postType]?.route}/${data?.[postType]?.slug}?id=${data?.[postType]?.databaseId}`}>{data?.[postType]?.title}</a>
        </h3>
        <Moment format="MMM DD YY" className={styles.article__card_date}>
          {data?.[postType]?.date}
        </Moment>
      </div>
      <div className={styles.inline_image_wrapper}>
        <div className={styles.article__card_image}>
          {data?.[postType]?.featuredImage ? (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src={data?.[postType]?.featuredImage?.node?.sourceUrl}
              alt={data?.[postType]?.featuredImage?.node?.altText}
            />
          ) : (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src="/images/Placeholder.jpg"
              alt="Placeholder"
            />
          )}
        </div>
      </div>
    </>
  );
}

Inline.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      date: PropTypes.string,
      focalPointX: PropTypes.number,
      focalPointY: PropTypes.number,
      terms: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            graphqlPluralName: PropTypes.string,
          })
        ),
      }),
      slug: PropTypes.string,
      title: PropTypes.string,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
          altText: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  postType: PropTypes.string.isRequired,
};
