/**
 * Render the Search component
 *
 * @param            props0
 * @param            props0.className
 * @param            props0.onClick
 * @param            props0
 * @param            props0.className
 * @param            props0.onClick
 * @param            props0
 * @param            props0.className
 * @param            props0.onClick
 * @return {Element}                  Seach component
 */
function Search({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.754"
      height="25.754"
      viewBox="0 0 25.754 25.754"
      onClick={onClick}
      className={className}
    >
      <defs />
      <path
        className="a"
        d="M20.285,16.858a10.185,10.185,0,1,0-3.429,3.426l6.91,6.91,3.427-3.429ZM11.6,17.889a6.283,6.283,0,1,1,6.286-6.279A6.293,6.293,0,0,1,11.6,17.889Z"
        transform="translate(-1.44 -1.44)"
      />
    </svg>
  );
}

export default Search;
