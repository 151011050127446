export const postTypes = {
  page: {
    pluralName: 'pages',
    route: '',
  },
  post: {
    pluralName: 'posts',
    route: 'articles',
    title: 'Articles',
    description: '',
  },
  audio: {
    pluralName: 'audios',
    route: 'audio',
    title: 'Audio',
    description: '',
  },
  event: {
    pluralName: 'events',
    route: 'events',
    title: 'Events',
    description: '',
  },
  podcast: {
    pluralName: 'podcasts',
    route: 'podcasts',
    title: 'Podcasts',
    description: '',
  },
  video: {
    pluralName: 'videos',
    route: 'videos',
    title: 'Videos',
    description: '',
  },
  partner: {
    pluralName: 'partners',
    route: 'partners',
    title: 'Partners',
    description: '',
  },
  conversation: {
    pluralName: 'conversations',
    route: 'conversations',
    title: 'Conversations',
    description: '',
  },
};

export const heirarchalPostTypes = ['page'];
