import PropTypes from 'prop-types';
import { postTypes } from '@/lib/wp/postTypes';
import styles from '../BlockArticleCard.module.scss';

/**
 * Render a Condensed variation of a card
 *
 * @param  {object}  props          component attributes
 * @param  {object}  props.data     graphql post data
 * @param  {string}  props.postType card post type
 * @return {Element}                Condensed card
 */
export default function Condensed({ data, postType }) {

  let tags = data?.[postType]?.terms?.nodes
  let firsttag = data?.[postType]?.primaryTag?.primaryTag || tags?.find((tag) => tag.__typename === 'Tag');
 
  
  return (
    <>
      <div className={styles.inline_content}>
        <ul className={styles.article__card_tags}>
        {data?.[postType]?.terms?.nodes.length &&
            <li>
                <a href={`/${firsttag?.taxonomy?.node?.graphqlSingleName}/${firsttag?.slug}`}>{firsttag?.name}</a>
              </li>
          }
        </ul>
        <h3 className={styles.article__card_title}>
          <a  href={`/${postTypes[postType]?.route}/${data?.[postType]?.slug}?id=${data?.[postType]?.databaseId}`}>{data?.[postType]?.title}</a>
        </h3>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.article__card_excerpt} dangerouslySetInnerHTML={{ __html: data?.[postType]?.excerpt }} />
      </div>
    </>
  );
}

Condensed.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      date: PropTypes.string,
      focalPointX: PropTypes.number,
      focalPointY: PropTypes.number,
      terms: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            graphqlPluralName: PropTypes.string,
          })
        ),
      }),
      slug: PropTypes.string,
      title: PropTypes.string,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
          altText: PropTypes.string,
        }),
      }),
      excerpt: PropTypes.string,
    }),
  }).isRequired,
  postType: PropTypes.string.isRequired,
};
