const bylineCustomFields = `
  bylineInformation {
    byline
    bylineTitle
    bylineAffiliation
    bylineWebsite
    firstFacebookSocialLink
    firstTwitterSocialLink
    firstLinkedinSocialLink
    byline2
    byline2Title
    byline2Affiliation
    byline2Website
    secondFacebookSocialLink
    secondTwitterSocialLink
    secondLinkedinSocialLink
  }
`;

export default bylineCustomFields;
