import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

/**
 * Initialize Apollo and merge with state
 *
 * @param  {object} apolloClient Apollo instance
 * @param  {*}      initialState Initial cache state
 * @return {object}              Apollo instance
 */
// eslint-disable-next-line import/prefer-default-export
export function initializeApollo(apolloClient, initialState = null) {
  if (initialState) {
    const existingCache = apolloClient.extract();
    const data = merge(initialState, existingCache, {
      arrayMerge: (desination, source) => [...source, ...desination.filter((d) => source.every((s) => !isEqual(d, s)))],
    });

    apolloClient.cache.restore(data);
  }

  return apolloClient;
}

/**
 * Pass down state to page props
 *
 * @param  {object} client    Apollo props
 * @param  {object} pageProps getStaticProps()
 * @return {object}           Updated page props
 */
export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client?.cache.extract();
  }

  return pageProps;
}
