import PropTypes from 'prop-types';
import cn from 'classnames';
import Moment from 'react-moment';
import { postTypes } from '@/lib/wp/postTypes';
import styles from '../BlockArticleCard.module.scss';

/**
 * Render a FeatureSmall variation of a card
 *
 * @param  {object}  props          component attributes
 * @param  {object}  props.data     graphql post data
 * @param  {string}  props.postType card post type
 * @return {Element}                FeatureSmall card
 */
export default function FeatureSmall({ data, postType }) {
  const tags = data?.[postType]?.terms?.nodes;
  let firsttag = data?.[postType]?.primaryTag?.primaryTag || tags?.find((tag) => tag.__typename === 'Tag');

  return (
    <>
      <div className={styles.article__card_image_small}>
        <div className={styles.article__card_image_rotate}>
          {data?.[postType]?.featuredImage ? (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src={data?.[postType]?.featuredImage?.node?.sourceUrl}
              alt={data?.[postType]?.featuredImage?.node?.altText}
            />
          ) : (
            <img
              style={{
                objectPosition: `${data?.[postType]?.focalPointX * 100}% ${data?.[postType]?.focalPointY * 100}%`,
              }}
              src="/images/Placeholder.jpg"
              alt="Placeholder"
            />
          )}
        </div>
      </div>
      <div className={styles['article__card_content_feature-small']}>
        <ul className={styles.article__card_tags}>
          {data?.[postType]?.terms?.nodes.length && (
            <li>
              <a href={`/${firsttag?.taxonomy?.node?.graphqlSingleName}/${firsttag?.slug}`}>{firsttag?.name}</a>
            </li>
          )}
        </ul>
        <h3 className={cn(styles.article__card_title, styles.article__card_title_full)}>
          <a href={`/${postTypes[postType]?.route}/${data?.[postType]?.slug}?id=${data?.[postType]?.databaseId}`}>{data?.[postType]?.title}</a>
        </h3>
        <div className={styles.article__card_meta}>
          <Moment format="MMM DD YY" className={styles.article__card_date}>
            {data?.[postType]?.date}
          </Moment>
          <span className={styles.article__card_reading}>{data?.[postType]?.readingTime} read</span>
        </div>
      </div>
    </>
  );
}

FeatureSmall.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      date: PropTypes.string,
      focalPointX: PropTypes.number,
      focalPointY: PropTypes.number,
      readingTime: PropTypes.string,
      terms: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            graphqlPluralName: PropTypes.string,
          })
        ),
      }),
      slug: PropTypes.string,
      title: PropTypes.string,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
          altText: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  postType: PropTypes.string.isRequired,
};
