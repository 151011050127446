import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';

const queryPostsByType = gql`
  query GET_POSTS_BY_TYPE(
    $first: Int
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
    $postType: ContentTypeEnum = POST
    $imageSize: MediaItemSizeEnum = LARGE
  ) {
    contentNodes(first: $first, where: { contentTypes: [$postType], orderby: { field: $orderBy, order: $order } }) {
      edges {
        node {
          contentType {
            node {
              graphqlSingleName
            }
          }
          ... on Post {
            ${postCardFields}
          }
          ... on Event {
            ${postCardFields}
          }
          ... on Video {
            ${postCardFields}
          }
          ... on Audio {
            ${postCardFields}
          }
        }
      }
    }
  }
`;

export default queryPostsByType;
