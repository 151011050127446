import Header from '@/components/Header';
import cn from 'classnames';
import Container from '@/components/Container';
import Link from 'next/link';
import style from './ErrorPage.module.scss';
import { useQuery } from '@apollo/client';
import BlockArticleCard from '@/components/BlockArticleCard';
import queryByType from '@/lib/wp/content/getPostByType';
import Head from "next/head";

export default function ErrorPage({ title, columns }) {
  const query = queryByType;
  const variables = { first: 3 };
  const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return <div>Loading data for nodes...</div>;
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      <Head>
          <title>Page not found - Health Data Management</title>
      </Head>
      <Header />
      <Container className={style.main}>
        <div className={style.errorText}>
          <h4 className={style.title}>{title}</h4>
          <Link href="/">
            <a className={style.button}>Return Home</a>
          </Link>
        </div>
        <div className={style.errorimage}>
          <img src="/images/Steth_Shadow.png" />
        </div>
      </Container>
      <Container className={style.main}>
        <div className={style.article__cards_title}>
          <h2>Popular</h2>
        </div>
      </Container>
      <Container className={cn(style.article__cards, `columns-${columns}`)}>
        {data?.contentNodes?.edges?.map((edge) => {
          const postID = edge?.node?.databaseId;
          const postType = edge?.node?.contentType?.node?.graphqlSingleName;
          return (
            <BlockArticleCard
              postID={postID}
              postType={postType}
              cardType="small-row"
              columnSpan={3}
              postData={edge?.node}
            />
          );
        })}
      </Container>
    </>
  );
}

ErrorPage.defaultProps = {
  columns: 3,
};
