import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { APOLLO_STATE_PROP_NAME, initializeApollo } from '@/lib/config';
import { useMemo } from 'react';

// TODO: Change this ENV to something more descriptive
export const wpApiBase = process.env.NEXT_PUBLIC_DOMAIN;
export const wpPreviewSecret = process.env.WORDPRESS_PREVIEW_SECRET;
const wpAppUser = process.env.WORDPRESS_APPLICATION_USERNAME;
const wpAppPass = process.env.WORDPRESS_APPLICATION_PASSWORD;

// Set WP application password auth header.
const wpAuthorization = Buffer.from(`${wpAppUser}:${wpAppPass}`).toString('base64');

let wpApolloClient;

/**
 * Creates basic Apollo client for WP
 *
 * @return {object} Apollo instance
 */

export function createWPApolloClient(auth = false, wpJWTAuth) {
  return new ApolloClient({
    ssrMode: false,
    link: new HttpLink({
      uri: `${wpApiBase}/graphql`,
      credentials: '',
      headers: wpJWTAuth
        ? {
            authorization: `Bearer ${wpJWTAuth}`,
          }
        : {
            authorization: auth ? `Basic ${wpAuthorization}` : '',
          },
    }),
    cache: new InMemoryCache(),
  });
}

/**
 * Initialize Apollo and merge with initial state
 *
 * @param  {*}      initialState Initial Apollo state
 * @return {object}              WP Apollo Instance
 */
export function initialzeWPApollo(initialState = null) {
  const initApolloClient = wpApolloClient ?? createWPApolloClient();
  const newApolloClient = initializeApollo(initApolloClient, initialState);

  if (typeof window === 'undefined') return newApolloClient;

  if (!wpApolloClient) wpApolloClient = newApolloClient;

  return newApolloClient;
}

/**
 * Only update when the cache value has changed.
 *
 * @param  {object} pageProps Props from getStaticProps().
 * @return {object}           WP Apollo client instance.
 */
export function useWPApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initialzeWPApollo(state), [state]);
  return store;
}
