const primaryTagCustomFields = `
  primaryTag {
    primaryTag {
      id
      slug
      name
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
  }
`;

export default primaryTagCustomFields;
