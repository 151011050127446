import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';

const queryPostById = gql`
  query GET_POST_BY_ID($id: ID!, $idType: PostIdType = DATABASE_ID, $imageSize: MediaItemSizeEnum = LARGE) {
    post(id: $id, idType: $idType) {
      ${postCardFields}
    }
  }
`;

export default queryPostById;
